import { gsap } from "gsap";

let _root;

export default class Login {
    constructor() {

        _root = this;
        this.init();
    }

    init() {
        const login_btn_yes = document.querySelector('#login_btn_yes');
        const login_btn_no = document.querySelector('#login_btn_no');
        const login_overlay = document.querySelector('#login');

        // Check if the login cookie exists
        if (!document.cookie.includes('login_accepted=true')) {
            gsap.set(login_overlay, { display: 'block' });

            if ('undefined' != typeof login_btn_yes && null != login_btn_yes) {
                _root.addEvent(login_btn_yes, 'click', _root.onClickYes);
            }
            if ('undefined' != typeof login_btn_no && null != login_btn_no) {
                _root.addEvent(login_btn_no, 'click', _root.onClickNo);
            }
            _root.unloadScrollBars();
        }
    }

    onClickYes(el) {
        el.preventDefault();
        const login_overlay = document.querySelector('#login');

        // Set cookie for 1 day
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        document.cookie = `login_accepted=true; expires=${expirationDate.toUTCString()}; path=/`;

        gsap.to(login_overlay, {
            duration: 1,
            autoAlpha: 0,
            onComplete: () => {
                login_overlay.style.display = 'none';
            }
        });
        _root.reloadScrollBars();
    }

    onClickNo(el) {

        el.preventDefault();
        const login_content_msg = document.querySelector('#login__content__msg');

        gsap.set(login_content_msg, { display: "block", autoAlpha: 0 });

        gsap.to(login_content_msg, {
            duration: 1,
            autoAlpha: 1
        });
    }

    reloadScrollBars() {
        document.documentElement.style.overflow = 'auto';
        document.body.scroll = 'yes';
    }

    unloadScrollBars() {
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = 'no';
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}
