import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class SectionLifestyle {
    constructor() {
        this.init();

        setTimeout(() => {
            this.init();
        }, 1000);
    }

    init() {

        const sectionLifestyle = document.querySelector('.section-lifestyle');

        if ('undefined' != typeof sectionLifestyle && null != sectionLifestyle) {

            const _tl = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionLifestyle,
                    invalidateOnRefresh: true,
                    scrub: 1,
                    // pin: true,
                    // start: "top top",
                    // end: () => "+=" + sectionEvents.scrollHeight,
                    id: "id-events",
                },
            });

            const bgHolder = sectionLifestyle.querySelector('.parallax-img__img');
            const heightDiff = bgHolder.offsetHeight - bgHolder.parentElement.offsetHeight;

            _tl.to( bgHolder, {
                y: -heightDiff,
                ease: "none"
            }, 0);

        }

    }

}